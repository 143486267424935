// client/src/templates/status.js

import React, { useEffect, useState } from "react";
import StatusChart from "../components/Status/StatusChart";
import Layout from '../components/App/Layout';
import SEO from '../components/App/SEO';
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import { Tooltip, OverlayTrigger, Button, Card, Spinner, Alert, Badge } from "react-bootstrap";
import { graphql, useStaticQuery, Link } from "gatsby";
import providers from "../../data/providers.json"; // Import providers data from JSON

const StatusTemplate = ({ pageContext }) => {
  const { providerSlug, providerName, providerIcon } = pageContext;
  const [statusData, setStatusData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [voting, setVoting] = useState(false);
  const [error, setError] = useState(null);
  const [shuffledProviders, setShuffledProviders] = useState([]);

  // Fetch API_URL and siteUrl from siteMetadata using useStaticQuery
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          API_URL
          siteUrl
        }
      }
    }
  `);

  const API_URL = data.site.siteMetadata.API_URL; // Use API_URL from siteMetadata
  const siteUrl = data.site.siteMetadata.siteUrl || "https://bluemail.me";
  const canonicalUrl = `${siteUrl}/status/${providerSlug || ""}/`;

  // Shuffle providers except the current one
  useEffect(() => {
    const shuffleProviders = () => {
      // Filter out the current provider and shuffle the rest
      const filteredProviders = providers.filter(
        (provider) => provider.title !== providerName
      );

      // Shuffle providers using Fisher-Yates algorithm
      for (let i = filteredProviders.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [filteredProviders[i], filteredProviders[j]] = [filteredProviders[j], filteredProviders[i]];
      }

      setShuffledProviders(filteredProviders.slice(0, 3)); // Select 3 providers
    };

    shuffleProviders();
  }, [providerName]);

  useEffect(() => {
    if (!providerSlug) return;

    const fetchStatusData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_URL}/status/${providerSlug}`);
        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        setStatusData(data);
      } catch (err) {
        console.error("Error fetching status:", err);
        setError(err.message);
        setStatusData(null);
      } finally {
        setLoading(false);
      }
    };

    fetchStatusData();
  }, [providerSlug, API_URL]);

  const handleVote = async (voteType) => {
    setVoting(true);
    setError(null);
    try {
      const response = await fetch(`${API_URL}/vote/${providerSlug}/${voteType}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Vote Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log(`Vote registered: ${voteType}`, data);

      // Refresh the status data after voting
      const statusResponse = await fetch(`${API_URL}/status/${providerSlug}`);
      if (!statusResponse.ok) {
        throw new Error(
          `Status Refresh Error: ${statusResponse.status} ${statusResponse.statusText}`
        );
      }
      const refreshedData = await statusResponse.json();
      setStatusData(refreshedData);
    } catch (err) {
      console.error("Error registering vote:", err);
      setError(err.message);
    } finally {
      setVoting(false);
    }
  };

  // Function to determine the service status based on reports in the last 2 hours
  const getServiceStatus = () => {
    if (!statusData || !statusData.reports) {
      console.log("No reports available");
      return `${providerName} Service is currently OK`;
    }

    // Filter reports from the last 2 hours
    const now = new Date();
    const reportsInLast2Hours = statusData.reports.filter((report) => {
      const reportTime = new Date(report.timestamp);
      return (now - reportTime) / (1000 * 60 * 60) <= 2;
    });

    console.log("Reports in the last 2 hours:", reportsInLast2Hours);

    if (reportsInLast2Hours.length > 10) {
      return `${providerName} Service suffers intermediate issues`;
    } else if (reportsInLast2Hours.length > 0) {
      return `${providerName} Service is experiencing some issues`;
    }

    return `${providerName} Service is OK`;
  };

  const renderTooltip = (props, text) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  return (
    <Layout>
      <SEO
        postTitle={`${providerName} status | BlueMail App`}
        postDescription={`Down detector for ${providerName}`}
        postImage='/img/OG/og_image-default-setup.png'
        canonicalUrl={canonicalUrl}
      />
      <Navbar />
      <div className="container my-5" style={{ paddingTop: '60px', minHeight: 'calc(100vh - 200px)' }}>
        {/* Combined Project Overview, Status Badge, and Voting Section */}
        <div className="row mb-4">
          <div className="col-md-6 mb-4">
            <Card className="shadow-sm">
              <Card.Body className="text-center">
                <h3 style={{ fontSize: '2rem' }}>Down Detector for {providerName}</h3>
                <Badge
                  className="mb-3"
                  style={{ fontSize: '1.5rem', padding: '10px 20px', backgroundColor: 'lightgrey', color: 'white' }}
                >
                  {getServiceStatus()}
                </Badge>
                <p className="mt-2" style={{ fontSize: '1.2rem' }}>
                  This detector monitors the real-time status of various service providers, allowing users to check updates, report issues, and suggest resolutions.
                </p>
                <div className="d-flex gap-3 justify-content-center mt-4">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => renderTooltip(props, "Report an Issue")}
                  >
                    <Button
                      variant="danger"
                      onClick={() => handleVote("down")}
                      disabled={voting}
                      aria-label="Down"
                      style={{ fontSize: '1.2rem' }}
                    >
                      Service is Down
                    </Button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => renderTooltip(props, "Report Resolution")}
                  >
                    <Button
                      variant="success"
                      onClick={() => handleVote("up")}
                      disabled={voting}
                      aria-label="Up Vote"
                      style={{ fontSize: '1.2rem' }}
                    >
                      Service is Up  
                    </Button>
                  </OverlayTrigger>
                </div>
                {/* Display Error Message */}
                {error && <Alert variant="danger" className="mt-3" style={{ fontSize: '1.1rem' }}>{error}</Alert>}
              </Card.Body>
            </Card>
          </div>

          {/* Provider Icon and Description */}
          <div className="col-md-6 mb-4">
            <Card className="shadow-sm">
              <Card.Img
                variant="top"
                src={providerIcon}
                alt={`${providerName} icon`}
                style={{ height: "150px", objectFit: "contain", padding: "20px" }}
              />
              <Card.Body>
                <Card.Title style={{ fontSize: '1.5rem' }}>{providerName} Status</Card.Title>
                <Card.Text className="text-muted" style={{ fontSize: '1.2rem' }}>
                  Check the latest status updates and report issues.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>

        {/* Full-Width Status Chart Section */}
        <div className="row">
          <div className="col-12 mb-4">
            <Card className="shadow-sm">
              <Card.Body>
                {loading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "400px" }}
                  >
                    <Spinner animation="border" variant="primary" role="status">
                      <span className="visually-hidden">Loading chart...</span>
                    </Spinner>
                  </div>
                ) : statusData ? (
                  <div style={{ width: '100%', height: '600px' }}>
                    <StatusChart website={providerSlug} />
                  </div>
                ) : (
                  <p className="text-danger" style={{ fontSize: '1.2rem' }}>No chart data available.</p>
                )}
              </Card.Body>
            </Card>
          </div>
        </div>

                {/* Shuffle and display three other providers */}
{/* Full-page container */}
<div className='d-flex justify-content-center align-items-center '>
  {/* Shuffle and display three other providers */}
  <div className='col-lg-9 col-md-6 col-xs-12 d-flex justify-content-center align-items-center providers-box mt-3'>
    {shuffledProviders.map((provider, index) => (
      <div className='content text-center' key={index}>
        <div className='col-lg-3 col-md-3 col-xs-12'>
          <Link
            className='other-providers'
            style={{ fontWeight: "700" }}
            to={`/status/${provider.slug}`}
          >
            {provider.name}
          </Link>
        </div>
      </div>
    ))}
  </div>
</div>


      </div>
      <Footer />
    </Layout>
  );
};

export default StatusTemplate;
