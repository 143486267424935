// client/src/components/StatusChart.js

import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Spinner, Alert } from "react-bootstrap";
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
  Filler,
} from "chart.js";
import { graphql, useStaticQuery } from "gatsby"; // Import useStaticQuery and graphql for querying API_URL
import "chartjs-adapter-date-fns"; // For date/time support

// Register Chart.js components
ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend,
  Filler
);

const StatusChart = ({ website }) => {
  const [chartData, setChartData] = useState(null);
  const [yAxisMax, setYAxisMax] = useState(10); // Initialize Y-axis max to 10
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch API_URL from siteMetadata using useStaticQuery
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          API_URL
        }
      }
    }
  `);

  const API_URL = data.site.siteMetadata.API_URL ; // Fallback to localhost if not defined

  useEffect(() => {
    // Function to fetch and process vote data
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_URL}/votes/${website}`);
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error(`No data found for provider: ${website}`);
          } else {
            throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);
          }
        }
        const result = await response.json();

        if (!result || !result.votes || result.votes.length === 0) {
          setError("No reports found for the past 24 hours.");
          setChartData(null);
          setYAxisMax(10); // Reset Y-axis max to 10
          return;
        }

        // Filter only 'down' votes
        const downVotes = result.votes.filter((vote) => vote.status === "down");

        if (downVotes.length === 0) {
          setError("No user reports found for the past 24 hours.");
          setChartData(null);
          setYAxisMax(10); // Reset Y-axis max to 10
          return;
        }

        // Define the time range: past 24 hours from now
        const now = new Date();
        const past24Hours = new Date(now.getTime() - 24 * 60 * 60 * 1000);

        // Define time intervals (e.g., every 5 minutes)
        const intervalMinutes = 5;
        const intervals = [];
        const intervalDuration = intervalMinutes * 60 * 1000; // in milliseconds
        for (
          let time = new Date(past24Hours);
          time <= now;
          time = new Date(time.getTime() + intervalDuration)
        ) {
          intervals.push(new Date(time));
        }

        // Initialize count object
        const downVoteCounts = {};
        intervals.forEach((interval) => {
          downVoteCounts[interval.getTime()] = 0;
        });

        // Assign down votes to their respective intervals
        downVotes.forEach((vote) => {
          const voteTime = new Date(vote.created_at);
          if (voteTime < past24Hours || voteTime > now) {
            return;
          }

          // Calculate the interval index
          const timeSincePast = voteTime.getTime() - past24Hours.getTime();
          const intervalIndex = Math.floor(timeSincePast / intervalDuration);
          const intervalTime = intervals[intervalIndex];

          if (intervalTime) {
            downVoteCounts[intervalTime.getTime()] += 1;
          }
        });

        // Prepare data points for the chart
        const dataPoints = intervals.map((interval) => ({
          x: interval,
          y: downVoteCounts[interval.getTime()],
        }));

        // Determine the maximum number of down votes to set Y-axis max
        const maxDownVotes = Math.max(...dataPoints.map((point) => point.y));
        const calculatedYAxisMax = Math.max(10, maxDownVotes);
        setYAxisMax(calculatedYAxisMax);

        // Create the chart data
        const data = {
          datasets: [
            {
              label: "Reports",
              data: dataPoints,
              borderColor: "rgba(255, 99, 132, 1)",
              backgroundColor: "rgba(255, 99, 132, 0.3)", // Fill color for the area
              fill: true, // Enable area fill
              tension: 0.4, // Smooth curves
              pointRadius: 0, // Hide data points
            },
          ],
        };

        setChartData(data);
        setError(null);
      } catch (err) {
        console.error("Error fetching or processing data:", err);
        setError(err.message);
        setChartData(null);
        setYAxisMax(10); // Reset Y-axis max to 10 on error
      } finally {
        setLoading(false); // Set loading to false after data fetch
      }
    };

    // Initial data fetch
    fetchData();

    // Optional: Refresh data every 5 minutes to keep the chart updated
    const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [website, API_URL]);

  return (
    <div style={{ width: "100%", height: "400px" }}>
      {error && <Alert variant="danger">{error}</Alert>}
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "700px" }}>
          <Spinner animation="border" variant="primary" role="status">
            <span className="visually-hidden">Loading chart...</span>
          </Spinner>
        </div>
      ) : chartData ? (
        <Line
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                position: "top",
              },
              title: {
                display: true,
                text: `Reports Over the Past 24 Hours for ${website}`,
                font: {
                  size: 20,
                  weight: 'bold',
                },
                padding: {
                  top: 20,
                  bottom: 20,
                },
              },
              tooltip: {
                mode: "index",
                intersect: false,
                callbacks: {
                  label: function (context) {
                    return `Down reports: ${context.parsed.y}`;
                  },
                },
              },
            },
            interaction: {
              mode: "nearest",
              intersect: false,
            },
            scales: {
              x: {
                type: "time",
                time: {
                  unit: "hour",
                  tooltipFormat: "HH:mm",
                  displayFormats: {
                    hour: "HH:mm",
                  },
                },
                title: {
                  display: true,
                  text: "Time",
                  font: {
                    size: 14,
                  },
                },
                ticks: {
                  source: 'auto',
                  autoSkip: false,
                  maxTicksLimit: 9,
                  callback: function(value, index, values) {
                    const date = new Date(value);
                    const hours = date.getHours();
                    if (hours % 3 === 0) {
                      return date.toLocaleTimeString([], { hour: '2-digit' });
                    }
                    return null;
                  },
                },
              },
              y: {
                beginAtZero: true,
                suggestedMax: yAxisMax,
                title: {
                  display: true,
                  text: "Number of reports",
                  font: {
                    size: 14,
                  },
                },
                ticks: {
                  stepSize: 1,
                  precision: 0,
                },
              },
            },
            elements: {
              line: {
                borderWidth: 2,
              },
            },
          }}
        />
      ) : (
        !error && <p>Loading user reports data...</p>
      )}
    </div>
  );
};

export default StatusChart;
